import React, { useState } from "react";
import { observer } from "mobx-react";
import { Spinner, Button, DatePicker } from "../../../../-common";
import classes from "./EditShippingDatesModal.module.scss";
import { eachWeekendOfInterval, lastDayOfMonth, parseISO } from "date-fns";
import PeopleStore from "src/stores/PeopleStore";

function getShippingWindowDateRanges() {
  const thisYear = new Date().getFullYear();
  const summerMinDate = parseISO(`${thisYear}-03-01`);
  const summerMaxDate = parseISO(`${thisYear + 1}-05-31`);
  const fallMinDate = parseISO(`${thisYear}-06-01`);
  const fallMaxDate = parseISO(`${thisYear + 1}-09-30`);
  const winterMinDate = parseISO(`${thisYear}-10-01`);
  const winterMaxDate = lastDayOfMonth(parseISO(`${thisYear + 1}-02-01`));
  return { thisYear, winterMinDate, winterMaxDate, summerMinDate, summerMaxDate, fallMinDate, fallMaxDate };
}

function EditShippingDatesModal({ user, close }) {
  const { teacherProfile: teacherInfo } = user || {};
  const { shippingDates } = teacherInfo || {};

  const [winter, setWinter] = useState(shippingDates?.winter);
  const [summer, setSummer] = useState(shippingDates?.summer);
  const [fall, setFall] = useState(shippingDates?.fall);

  const [updating, setUpdating] = useState(false);

  const updateEnabled = winter && summer && fall;

  const updateShippingDates = async () => {
    setUpdating(true);
    await PeopleStore.updateShippingDatesForUser({ userId: teacherInfo?.id, shippingDates: { winter, summer, fall } });
    setUpdating(false);
    close();
  };

  const {
    thisYear,
    winterMinDate,
    winterMaxDate,
    summerMinDate,
    summerMaxDate,
    fallMinDate,
    fallMaxDate
  } = getShippingWindowDateRanges();
  const thisYearAbbrev = "'" + String(thisYear).slice(2, 4);
  const nextYearAbbrev = "'" + String(thisYear + 1).slice(2, 4);

  const cancelButtonStyles = updating ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = updateEnabled
    ? updating
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = updating ? <Spinner /> : "Update";

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.title}>Manage Shipping Dates</div>
        <div className={`${classes.inputTitle} ${classes.first}`}>
          Spring/Summer {thisYearAbbrev} window <span className={classes.inputSubTitle}>(Mar 1 - Jun 30)</span>
        </div>
        <DatePicker
          placeholder="Select date..."
          value={summer}
          minDate={summerMinDate}
          maxDate={summerMaxDate}
          excludeDates={eachWeekendOfInterval({ start: summerMinDate, end: summerMaxDate })}
          onChange={setSummer}
        />
        <div className={classes.inputTitle}>
          Fall {thisYearAbbrev} window <span className={classes.inputSubTitle}>(Jul 1 - Sep 30)</span>
        </div>
        <DatePicker
          placeholder="Select date..."
          value={fall}
          minDate={fallMinDate}
          maxDate={fallMaxDate}
          excludeDates={eachWeekendOfInterval({ start: fallMinDate, end: fallMaxDate })}
          onChange={setFall}
        />
        <div className={classes.inputTitle}>
          Winter {thisYearAbbrev} - {nextYearAbbrev} window{" "}
          <span className={classes.inputSubTitle}> (Nov 1 - Feb 30)</span>
        </div>
        <DatePicker
          placeholder="Select date..."
          value={winter}
          minDate={winterMinDate}
          maxDate={winterMaxDate}
          excludeDates={eachWeekendOfInterval({ start: winterMinDate, end: winterMaxDate })}
          onChange={setWinter}
        />
      </div>
      <div className={classes.bottomButtons}>
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className={classes.leftButtons}>
          <Button type="primary" action={updateShippingDates} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(EditShippingDatesModal);
