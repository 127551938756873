import activityIcon from "./ActivityIcon";
import chevronLeftIcon from "./ChevronLeftIcon";
import dollarIcon from "./DollarIcon";
import helpCircleIcon from "./HelpCircleIcon";
import inboxIcon from "./InboxIcon";
import mailIcon from "./MailIcon";
import userIcon from "./UserIcon";
import checkIcon from "./CheckIcon";
import clockIcon from "./ClockIcon";
import editIcon from "./EditIcon";
import homeIcon from "./HomeIcon";
import lineIcon from "./LineIcon";
import plusIcon from "./PlusIcon";

export const ActivityIcon = activityIcon;
export const ChevronLeftIcon = chevronLeftIcon;
export const DollarIcon = dollarIcon;
export const HelpCircleIcon = helpCircleIcon;
export const InboxIcon = inboxIcon;
export const MailIcon = mailIcon;
export const UserIcon = userIcon;
export const CheckIcon = checkIcon;
export const ClockIcon = clockIcon;
export const EditIcon = editIcon;
export const HomeIcon = homeIcon;
export const LineIcon = lineIcon;
export const PlusIcon = plusIcon;

const allIcons = {
  ActivityIcon,
  ChevronLeftIcon,
  DollarIcon,
  HelpCircleIcon,
  InboxIcon,
  MailIcon,
  UserIcon,
  CheckIcon,
  ClockIcon,
  EditIcon,
  HomeIcon,
  LineIcon,
  PlusIcon
};

export default allIcons;
