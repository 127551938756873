import React from "react";
import groupBy from "lodash.groupby";
import RosterRow from "./RosterRow";
import "./StudentsTable.scss";

const StudentsTable = ({ teacher, class: classItem, students, classId, courseId, forDroppedStudents = false }) => {
  const studentsGroupedByParent = Object.values(groupBy(students, "connectionId"))
    ?.map(students => ({
      parent: students?.[0]?.parent,
      materials: students?.[0]?.materials,
      students: students?.map(({ parent, materials, ...s }) => s)
    }))
    ?.sort((a, b) => (a?.parent?.lastName > b?.parent?.lastName ? 1 : -1));
  const studentRows = studentsGroupedByParent?.map((rosterRow, i) => (
    <RosterRow
      {...{ ...rosterRow, teacher, class: classItem, classId, courseId, forDroppedStudents }}
      key={rosterRow?.parent?.id || i}
    />
  ));

  return (
    <div className="table-wrapper">
      <table className="students-table">
        <thead>
          <tr>
            <th>Parent</th>
            <th>Materials</th>
            <th>Students</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{studentRows}</tbody>
      </table>
    </div>
  );
};

export default StudentsTable;
